import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useOrder} from '../../../contexts/OrderContext';
import {usePage} from '../../../contexts/PageContext';
import './CheckoutTab.View.scss';
import {appApiUrl} from '../../../utils/config';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import CheckoutContent from './CheckoutContent.View';
import {Loader} from '../../ui/loader';

const stripePromise = loadStripe('pk_test_51IdiDtFh7Dasx8MokymoBsEmIltaHNS1Rv1wfTzwJ8LB0ndtWHyuPccrv4r46xEd7ITa2HbEo9Qci6xZXMadIO7C00KVTZEEwl');

const defaultAppearance = {
    theme: 'stripe',
    labels: 'floating',
    rules: {
        '.Input:focus': {
            boxShadow: 'none',
            outline: 0,
            borderColor: '#000000',
        },
    },
};

const CheckoutTab = () => {
    const {clientSecret, setClientSecret, country} = usePage();
    const {cartItems} = useOrder();

    const [loading, setLoading] = useState(false);
    const [appearance, setAppearance] = useState({
        time: Date.now(),
        data: defaultAppearance,
    });

    const isFetching = useRef(false);

    useEffect(() => {
        // Ensure the appearance object triggers re-render for Stripe Elements
        setAppearance((prev) => ({...prev, time: Date.now()}));
    }, []);

    const fetchClientSecret = async () => {
        if (isFetching.current || !cartItems?.length || clientSecret) return;

        isFetching.current = true;
        setLoading(true);

        try {
            const metadata = cartItems.map(({art_id, art_name, artist_name, quantity, itemSize: artsize}) => ({
                artsize,
                art_id,
                art_name,
                artist_name,
                quantity,
            }));
            const totalPrice = cartItems.reduce((acc, curr) => acc + curr.price * curr.quantity, 0);

            const response = await fetch(`${appApiUrl}/create-payment-intent/`, {
                headers: {'Content-Type': 'application/json'},
                method: 'POST',
                body: JSON.stringify({
                    amount: totalPrice,
                    active_country: country || 'us',
                    metadata,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch client secret');
            }

            const data = await response.json();
            setClientSecret(data?.resp?.client_secret);
        } catch (err) {
            console.error('Error fetching client secret:', err);
            alert('Unable to process your payment. Please try again later.');
        } finally {
            isFetching.current = false;
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchClientSecret();
    }, [cartItems, clientSecret]);

    return (
        <Fragment>
            {loading ? (
                <Loader/>
            ) : (
                <Fragment>
                    {stripePromise && clientSecret ? (
                        <div key={appearance.time}>
                            <Elements stripe={stripePromise} options={{clientSecret, appearance: appearance.data}}>
                                <CheckoutContent/>
                            </Elements>
                        </div>
                    ) : (
                        <div className="error-message">
                            <p>Failed to load the payment form. Please try refreshing the page or contact support.</p>
                        </div>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default CheckoutTab;
