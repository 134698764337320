import React from "react";

import { useOrder } from "../../../contexts/OrderContext";
import { AiOutlineClose, AiOutlineMinus } from "react-icons/ai";
import { AiOutlinePlus } from "react-icons/ai";

const ItemCounter = ({ itemID, id }) => {
  const { setCartItems, cartItems, removeFromCart } = useOrder();
  const itemQty = cartItems.find((item) => item.id === id).quantity || 1;

  const itemQtyIncrease = () => {
    setCartItems((prev) => {
      return prev.map((item) => {
        if (item.id !== id) return item;
        return { ...item, quantity: item.quantity + 1 };
      });
    });
  };
  const itemQtyDecrease = () => {
    if (itemQty === 1) {
      removeFromCart(id);
    }
    if (itemQty > 1) {
      setCartItems((prev) => {
        return prev.map((item) => {
          if (item.id !== id) return item;
          return { ...item, quantity: item.quantity - 1 };
        });
      });
    }
  };

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex align-items-center justify-content-between counter-container">
        <div className="minus-item" 
          onClick={e=>{
            e.stopPropagation()
            itemQtyDecrease()
          }}>
          <AiOutlineMinus />
        </div>
        <div className="item-quantity">{itemQty}</div>
        <div className="plus-item" 
          onClick={e=>{
            e.stopPropagation()
            itemQtyIncrease()}
          }>
          <AiOutlinePlus />
        </div>
      </div>
      <p 
        onClick={(e) => {
          e.stopPropagation()
          removeFromCart(id)
        }} 
        className="remove-item">
        <AiOutlineClose />
      </p>
    </div>
  );
};

export default ItemCounter;
